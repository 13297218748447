<template>
  <v-container class="container-page">
    <v-card class="mt-0 transparent">
      <v-card-text class="px-0 py-0">
        <h1 class="main-title">
          Pesquisa de Satisfação
        </h1>
        <v-container
          fluid
          grid-list-lg
          class="mt-0">
          <v-layout
            row
            wrap>
            <v-flex
              xs12
              sm3>
              <widget-survey-one
                :data="survey[0]"
                title="Como você avalia sua experiência conosco?" />
            </v-flex>
            <v-flex
              xs12
              sm3>
              <widget-survey-one
                :data="survey[1]"
                title="Como você avalia o tempo gasto nessa operação" />
            </v-flex>
            <v-flex
              xs12
              sm3>
              <widget-survey-one
                :data="survey[2]"
                title="Como você avalia a nossa comunicação no convite enviado" />
            </v-flex>
            <v-flex
              xs12
              sm3>
              <widget-survey-one
                :data="survey[3]"
                title="Você utilizaria novamente nossa plataforma?" />
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
  import WidgetSurveyOne from '@/components/ui/widgets/survey/WidgetSurveyOne'

  export default {
    components: {
      WidgetSurveyOne
    },
    data () {
      return {
        survey: [
          {
            legend: {
              show: false
            },
            series: [5, 25, 12, 48],
            chartOptions: {
              theme: {
                monochrome: {
                  enabled: true
                }
              },
              labels: ['Péssima ', 'Razoável ', 'Boa ', 'Excelente '],
              legend: {
                show: true,
                position: 'bottom',
                horizontalAlign: 'left',
                textAnchor: 'start',
                containerMargin: {
                  right: 15,
                  top: 10
                },
                labels: {
                  color: this.$vuetify.theme.themes.light.darkGray
                },
                markers: {
                  size: 5,
                  strokeWidth: 0,
                  offsetX: 0,
                  offsetY: 0,
                  radius: 1,
                  shape: 'circle'
                }
              },
              dataLabels: {
                enabled: true
              }
            }
          },
          {
            legend: {
              show: false
            },
            series: [3, 7, 35, 55],
            chartOptions: {
              labels: ['Insatisfatório', 'Satisfatório', 'Rápido', 'Muito rápido'],
              theme: {
                monochrome: {
                  enabled: true
                }
              },
              legend: {
                show: true,
                position: 'bottom',
                horizontalAlign: 'left',
                textAnchor: 'start',
                containerMargin: {
                  left: 0,
                  top: 10
                },
                labels: {
                  color: this.$vuetify.theme.themes.light.darkGray
                },
                markers: {
                  size: 5,
                  strokeWidth: 0,
                  offsetX: 0,
                  offsetY: 0,
                  radius: 1,
                  shape: 'circle'
                }
              },
              dataLabels: {
                enabled: true
              }
            }
          },
          {
            legend: {
              show: false
            },
            series: [1, 12, 17, 70],
            chartOptions: {
              labels: ['Péssima', 'Razoável', 'Boa', 'Excelente'],
              theme: {
                monochrome: {
                  enabled: true
                }
              },
              legend: {
                show: true,
                position: 'bottom',
                horizontalAlign: 'left',
                textAnchor: 'start',
                containerMargin: {
                  right: 15,
                  top: 10
                },
                labels: {
                  color: this.$vuetify.theme.themes.light.darkGray
                },
                markers: {
                  size: 5,
                  strokeWidth: 0,
                  offsetX: 0,
                  offsetY: 0,
                  radius: 1,
                  shape: 'circle'
                }
              },
              dataLabels: {
                enabled: true
              }
            }
          },
          {
            legend: {
              show: false
            },
            series: [5, 15, 30, 50],
            chartOptions: {
              labels: ['Não', 'Não sei', 'Talvez', 'Com certeza'],
              theme: {
                monochrome: {
                  enabled: true
                }
              },
              legend: {
                show: true,
                position: 'bottom',
                horizontalAlign: 'left',
                textAnchor: 'start',
                containerMargin: {
                  left: 0,
                  top: 10
                },
                labels: {
                  color: this.$vuetify.theme.themes.light.darkGray
                },
                markers: {
                  size: 5,
                  strokeWidth: 0,
                  offsetX: 0,
                  offsetY: 0,
                  radius: 1,
                  shape: 'circle'
                }
              },
              dataLabels: {
                enabled: true
              }
            }
          }
        ]
      }
    }
  }
</script>

