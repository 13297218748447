<template>
  <widget-box
    :title="title"
    @screen.once="showElement">
    <v-layout
      row
      wrap>
      <loading
        v-if="loading > 0"
        class="absolute-center" />
      <v-flex
        v-else
        md12
        class="widget-legend px-0 pt-0">
        <apexcharts
          width="100%"
          type="pie"
          :options="data.chartOptions"
          :series="data.series" />
      </v-flex>
    </v-layout>
  </widget-box>
</template>

<script>
  import widgetsMixin from '@/mixins/widgets'

  export default {
    mixins: [ widgetsMixin ],
    methods: {
      showElement () {
        this.loading = 0
      }
    }
  }
</script>

<style lang="sass" scoped>
  .widget-legend
    // max-height: 300px!important
    overflow: hidden
    margin-top: -30px

</style>
